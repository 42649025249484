.admin-draggable {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  background-image: url("../../assets/images/forestBackground.png");
  background-size: cover;
}

.admin-draggable__map {
  width: max-content;
}
