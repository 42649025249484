@font-face {
  font-family: "Mulish";
  src: url("Mulish-Black.eot");
  src: url("Mulish-Black.eot?#iefix") format("embedded-opentype"),
    url("Mulish-Black.woff2") format("woff2"),
    url("Mulish-Black.woff") format("woff"),
    url("Mulish-black.ttf") format("truetype"),
    url("Mulish-Black.svg#Mulish-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url("Mulish-Light.eot");
  src: url("Mulish-Light.eot?#iefix") format("embedded-opentype"),
    url("Mulish-Light.woff2") format("woff2"),
    url("Mulish-Light.woff") format("woff"),
    url("Mulish-Light.ttf") format("truetype"),
    url("Mulish-Light.svg#Mulish-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: "Onest-VariableFont_wght";
    src: url("Onest-VariableFont_wght.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: "gothampro_bold";
  src: url("gothampro_bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url("Mulish-Regular.eot");
  src: url("Mulish-Regular.eot?#iefix") format("embedded-opentype"),
    url("Mulish-Regular.woff2") format("woff2"),
    url("Mulish-Regular.woff") format("woff"),
    url("Mulish-Regular.ttf") format("truetype"),
    url("Mulish-Regular.svg#Mulish-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url("Mulish-Bold.eot");
  src: url("Mulish-Bold.eot?#iefix") format("embedded-opentype"),
    url("Mulish-Bold.woff2") format("woff2"),
    url("Mulish-Bold.woff") format("woff"),
    url("Mulish-Bold.ttf") format("truetype"),
    url("Mulish-Bold.svg#Mulish-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url("Mulish-Medium.eot");
  src: url("Mulish-Medium.eot?#iefix") format("embedded-opentype"),
    url("Mulish-Medium.woff2") format("woff2"),
    url("Mulish-Medium.woff") format("woff"),
    url("Mulish-Medium.ttf") format("truetype"),
    url("Mulish-Medium.svg#Mulish-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url("Mulish-SemiBold.eot");
  src: url("Mulish-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("Mulish-SemiBold.woff2") format("woff2"),
    url("Mulish-SemiBold.woff") format("woff"),
    url("Mulish-SemiBold.ttf") format("truetype"),
    url("Mulish-SemiBold.svg#Mulish-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Pieces of Eight Cyrillic AA";
  src: url("PiecesofEightCyrillicAA.eot");
  src: url("PiecesofEightCyrillicAA.eot?#iefix") format("embedded-opentype"),
    url("PiecesofEightCyrillicAA.woff2") format("woff2"),
    url("PiecesofEightCyrillicAA.woff") format("woff"),
    url("PiecesofEightCyrillicAA.ttf") format("truetype"),
    url("PiecesofEightCyrillicAA.svg#PiecesofEightCyrillicAA") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
