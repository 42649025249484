.admin-header {
  position: fixed;
  width: 100%;
  padding: 10px 30px;

  background-color: #0e0e0e;

  box-sizing: border-box;
  z-index: 9999;
}

.admin-header__container {
  display: flex;
  align-items: center;

  max-width: 1100px;
  margin: 0 auto;
}

.admin-header__logo {
  margin-right: 30px;
}

.admin-header__button {
  padding: 10px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  text-decoration: none;
}

.admin-header__button:hover {
  opacity: 0.7;
}

.admin-header__logout {
  margin-left: auto;
  padding: 10px 15px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  background-color: #c1c1c1;
  outline: none;
  border: none;
  border-radius: 5px;

  color: #ffffff;
  cursor: pointer;
}

.admin-header__logout:hover {
  opacity: 0.7;
}
