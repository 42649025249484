.topbar {
  display: flex;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  /* width: 100%; */
  height: 70px;

  padding: 0 70px;

  background: radial-gradient(#0B7523, #074F17);

  z-index: 1;
}

.logo {
  display: flex;

  width: 171px;
  height: 25px;

  background-image: url("/src/assets/images/svg/logo-header.svg");
  background-repeat: no-repeat;
  background-position: center;

  padding: 0 20px 0 20px;

  margin-left: 2px;

}
