.wrapper {
  min-height: 100vh;
  min-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #0e0e0e;
  background: linear-gradient(
      112.87deg,
      rgba(0, 0, 0, 0.85) 13.35%,
      rgba(32, 43, 56, 0.57) 90.09%
    ),
    url("./../../assets//images/piratBlackMark.jpeg") center no-repeat;
  background-size: cover;
  overflow: hidden;
}

.header {
  align-items: flex-start;

  max-width: 1440px;
  width: 100%;

  margin-top: 70px;
}

.header__logo {
  margin-left: 80px;
}

.info {
  display: flex;
  justify-content: space-between;

  max-width: 1440px;
  width: 100%;

  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 130px;

  box-sizing: border-box;

  position: relative;
  /*overflow: hidden;*/
}

.info__background {
  position: absolute;
  top: 0;
  left: 0;

  width: 500px;
  height: 500px;
}

.info__container {
  /* display: flex;
  flex-direction: column;
  flex: 1; */
}

.info__container_left {
  padding-top: 122px;
}

.info__container_right {
  display: flex;
  margin-left: auto;
  margin-right: 60px;
  padding-top: 77px;
}

.info__title {
  margin: 0;
  margin-bottom: 47px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #ffffff;
}

.info__subtitle {
  margin: 0;

  font-family: "Pieces of Eight Cyrillic AA";
  font-style: normal;
  font-weight: 400;
  font-size: 115px;
  line-height: 92%;
  /* or 109px */

  text-transform: uppercase;

  background: linear-gradient(
      180deg,
      rgba(255, 184, 0, 0) 36.75%,
      rgba(121, 36, 0, 0.4) 78.31%
    ),
    linear-gradient(104.97deg, #ffcf55 1.66%, #ff7a00 88.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.info__about {
  max-width: 620px;

  margin: 0;
  margin-top: 33px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 145%;
  /* or 26px */

  color: #ffffff;
}

.wheel {
  position: absolute;
  bottom: -231px;
  right: -158px;

  pointer-events: none;
}
