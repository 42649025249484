.tabs {
  width: 100%;
  /* height: 540px; */
  height: calc(100% - 40px - 38px);
  min-height: 400px;
  margin-top: 30px;
  padding-right: 16px;

  overflow-y: auto;
  overflow-x: hidden;
}

.tabs::-webkit-scrollbar {
  width: 7px;
}
.tabs::-webkit-scrollbar-track {
  background-color: #fff7e9;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.tabs::-webkit-scrollbar-thumb {
  background: linear-gradient(270deg, #961e02 0%, #e32a00 100%);
  border-radius: 10px;
}

/* Tab Navigation */
ul.nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  border-bottom: 2px solid #a92406;
  border-radius: 10px 10px 0px 0px;
  padding-left: 0px;
  color: #a92406;
}

ul.nav li {
  padding: 12px 50px;
  list-style: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}

ul.nav li:hover {
  /*background: rgba(50, 224, 196, 0.15);*/
}

ul.nav li.active {
  border: none;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  background: linear-gradient(#e32a00 50%, #8C1313 100%);
  color: #ffffff;
}

/* Tab Content Styles */
.tab-content {
  /*font-size: 2rem;*/
  /*text-align: center;*/
}
