.modal-event {
  /* display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap; */

  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill, minmax(230px, 1fr)); */
  /* grid-gap: 20px; */

  display: flex;
  gap: 30px;

  width: 100%;
  height: 87%;

  margin-top: 20px;
  padding-right: 16px;

  overflow-y: auto;
}

.modal-event::-webkit-scrollbar {
  width: 7px;
}
.modal-event::-webkit-scrollbar-track {
  background-color: #fff7e9;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.modal-event::-webkit-scrollbar-thumb {
  background: linear-gradient(270deg, #961e02 0%, #e32a00 100%);
  border-radius: 10px;
}

.modal-event__subtitle {
  margin: 0;
  margin-bottom: 20px;

  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.modal-event__text {
  max-width: 479px;

  margin: 0;
  margin-bottom: 40px;

  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #786A6A;
}

.event-figure {
  align-self: flex-start;

  max-width: 450px;

  margin: 0;
  padding: 30px;

  background: rgba(255, 255, 255, 0.57);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  box-sizing: border-box;
  overflow: hidden;
}

.event-figure__image {
  width: 390px;

  border-radius: 10px;
  object-fit: cover;
}

.event-figure__figcaption {
  margin-top: 20px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;

  color: #a92406;
}

.event-effect-name__image {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 30px;
  font-size: 16px;
  border-radius: 50%;
  background-color: #39ade3;
  color: #ffffff;
}
