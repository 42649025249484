.chat {
  display: flex;

  height: 66%;

  margin-top: 20px;
}

.chat__crews {
  width: 26%;
  height: 100%;
  /* max-width: 260px; */

  overflow-y: auto;
}

.chat__crews::-webkit-scrollbar {
  width: 7px;
}
.chat__crews::-webkit-scrollbar-track {
  background-color: #fff7e9;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.chat__crews::-webkit-scrollbar-thumb {
  background: linear-gradient(270deg, #961e02 0%, #e32a00 100%);
  border-radius: 10px;
}

.chat__messages {
  width: 74%;

  padding-left: 22px;

  box-sizing: border-box;
}

.chat__send {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
}

.send-message {
  width: 277px;
  height: 50px;
  background: linear-gradient(#f1ad30 50%, #9E6F12 100%);
  border-radius: 50px;
  border: none;

  margin-top: 20px;
  margin-bottom: 20px;
}

.crew {
  display: flex;

  margin-bottom: 20px;
}

.chat-avatar {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  background: #2cf0aa;

  border-radius: 20px;

  font-family: "Mulish";
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
  display: flex;
  text-transform: uppercase;

  color: #ffffff;
}

.crew__block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-left: 11px;
}

.crew__name {
  max-width: 160px;
  margin: 0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-family: "Mulish";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;

  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.crew__position {
  max-width: 160px;
  margin: 0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-family: "Mulish";
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;

  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.crew__status {
  width: 8px;
  height: 8px;

  margin-left: 11px;
  margin-top: 5px;

  border-radius: 4px;
}

.crew__status_offline {
  background: #de4e4e;
}

.crew__status_online {
  background: #3ec827;
}

.message-input {
  max-width: 703px;
  width: 100%;
  height: 110px;

  padding: 15px;

  border: 2px solid #E9E9E9;
  border-radius: 10px;

  font-family: "Mulish";
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #000000;

  opacity: 0.8;

  resize: none;
  box-sizing: border-box;
}

.messages {
  width: 100%;
  height: calc(100% - 110px - 40px);
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */

  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 8px;

  background: linear-gradient(#FCFCFC, #EDEDED);
  border: 2px solid #E9E9E9;
  border-radius: 10px;

  overflow-y: scroll;
}

.messages::-webkit-scrollbar {
  width: 7px;
}
.messages::-webkit-scrollbar-track {
  background-color: #fff7e9;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.messages::-webkit-scrollbar-thumb {
  background: linear-gradient(270deg, #961e02 0%, #e32a00 100%);
  border-radius: 10px;
}

.message {
  display: flex;

  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;

  /* max-width: 60%; */
}

.message__text {
  max-width: 60%;
  margin: 0;
  margin-left: 10px;
  padding: 20px 20px 5px 20px;

  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(65, 16, 5, 0.1);
  border-radius: 10px;
}

.message__text_active-user {
  margin-right: 30px;
  margin-left: auto;

  background: #ffeccd;
}

.message__image {
  padding: 5px;
  width: 100px;
  object-fit: contain;
}

.message__time {
  margin: 0;
  margin-top: 8px;

  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;

  color: #383838;

  opacity: 0.3;
}

.message-time {
  font-family: "Mulish";
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;

  color: #a92406;

  opacity: 0.4;
}
