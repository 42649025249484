.team {
  /* margin-top: 20px;
    padding-right: 30px;
    height: 580px; */
  height: calc(100% - 40px);
  overflow-y: auto;
}

.team::-webkit-scrollbar {
  width: 7px;
}
.team::-webkit-scrollbar-track {
  background-color: #fff7e9;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.team::-webkit-scrollbar-thumb {
  background: linear-gradient(270deg, #961e02 0%, #e32a00 100%);
  border-radius: 10px;
}

.user-list {
  border-collapse: separate;
  border-spacing: 0 20px;
}
