/* .fragments-full {
    width: 100vw;
    height: calc(100vh - 85px - 70px);
    overflow: hidden;
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #FFD28A 100%);
    text-align: center;
}

.fragments-full__title {
    margin: 0;
    margin-top: 50px;
    font-family: 'Pieces of Eight Cyrillic AA';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 64px;
    text-transform: uppercase;
    color: #A92406;
}

.fragments-full__text {
    max-width: 885px;
    margin: 20px auto 0;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    color: #A92406;
}

.fragments-full__image {
    max-width: 630px;
    position: relative;
    margin: 30px auto;
    text-align: center;
}

.chests {
    position: absolute;
}

.chests-1 {
    right: 98%;
    bottom: 0;
}
.chests-2 {
    right: 80%;
    bottom: -7%;
}
.chests-3 {
    left: 83%;
    bottom: -6%;
} */

.congratulations {
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;

  /* height: calc(100vh - 85px - 70px); */

  background: #FFFFFF;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  border-radius: 20px;}

.congratulations__title {
  margin: 0;
  padding-top: 32px;

  font-family: "Onest-VariableFont_wght";
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  text-align: center;

  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.congratulations__subtitle {
  max-width: 498px;

  margin: 0;
  margin-top: 30px;

  font-family: "Onest-VariableFont_wght";
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;


  color: #341A1A;
}

.congratulations__button {
  height: 60px;

  margin-top: 26px;
  margin-bottom: 141px;

  background: linear-gradient(175.27deg, #cc2600 49.98%, #921e04 96.18%),
    linear-gradient(184.73deg, #921e04 3.82%, #d22700 50.02%);
}

.congratulations__images {
  position: relative;

  margin-top: 34px;
}

.congratulations__gold-with-rubies {
  position: absolute;
  bottom: -29px;
  right: -220px;
}

.congratulations__pile-of-gold {
  position: absolute;
  bottom: -35px;
  left: -251px;
}

.congratulations__chest-with-gold {
  position: absolute;
  bottom: 0px;
  left: -308px;
}

.congratulations__fake {
  width: 630px;
  height: 473px;
}

.congratulations__fragments {
  position: absolute;
  top: -116px;
  left: 26px;
  transform: scale(0.84);
}

.congratulations__close-button {
  position: absolute;
  top: 0;
  right: 0;
}
