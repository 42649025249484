.fragments {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.fragments-count__present {
  margin: 0 15px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #a92406;
}

.fragments-count__full {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #a92406;
}

.fragments-container {
  height: calc(100% - 50px);
  overflow-y: auto;

}

.fragments-container::-webkit-scrollbar {
  width: 7px;
}
.fragments-container::-webkit-scrollbar-track {
  background-color: #fff7e9;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.fragments-container::-webkit-scrollbar-thumb {
  background: linear-gradient(270deg, #961e02 0%, #e32a00 100%);
  border-radius: 10px;
}

.fragments {
  width: 578px;
  height: 576px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  margin: 20px auto;
  padding: 20px;

  /*background: linear-gradient(119.12deg, #cca151 0.39%, #ffcd71 100%);*/
  border-radius: 10px;
  box-sizing: border-box;
}

.fragments-item {
  width: 145px;
  height: 145px;


}

.fragments-item img {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}

.fragments-item__1 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}

.fragments-item__2 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__3 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__4 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__5 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__6 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__7 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__8 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__9 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__10 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__11 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__12 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__13 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__14 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__15 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}
.fragments-item__16 {
  transform: scale(1.03);
  height: 145px;
  width: 145px;
}

.fragments-item__img {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.95);
}

.fragments-item__img_1 {
  top: -2px;
  left: 1px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_2 {
  top: -2px;
  left: 0px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_3 {
  top: -1px;
  left: -1px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_4 {
  top: -1px;
  left: -1px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_5 {
  top: -1px;
  left: 0px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_6 {
  top: 2px;
  left: 3px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_7 {
  top: -3px;
  left: 1px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_8 {
  top: -2px;
  left: 0px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_9 {
  top: -1px;
  left: 0px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_10 {
  top: -1px;
  left: 0px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_11 {
  top: -1px;
  left: 3px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_12 {
  top: 0px;
  left: 2px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_13 {
  top: -1px;
  left: 0px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_14 {
  top: -1px;
  left: 1px;
  width: 145px;
  height: 145px;
}
.fragments-item__img_15 {
  top: -1px;
  left: 0px;
  width: 145px;
  height: 145px;
}
