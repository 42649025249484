@keyframes preloader {
  0% {
    opacity: 1;
    height: 100vh;
  }
  70% {
    opacity: 1;
    height: 100vh;
  }
  99% {
    opacity: 0;
    height: 100vh;
  }
  100% {
    height: 30vh;
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color: #a3e1ff; */
  background-image: url("../../assets/images/forestBackground.png");
  background-size: cover;

  z-index: 123;
  overflow: hidden;

  opacity: 0;
  height: 0;

  pointer-events: none;
  /* animation: preloader 3s linear; */
}

.preloader__loader {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.preloader__tentacle1 {
  position: absolute;
  top: 10vh;
  right: -40px;
  transform: rotate(321deg);
  user-select: none;
}

.preloader__tentacle2 {
  position: absolute;
  top: -40px;
  left: 20vw;
  transform: rotate(133deg);
}

.preloader__tentacle3 {
  position: absolute;
  bottom: -80px;
  right: 23vw;
  user-select: none;
}

.preloader__tentacle4 {
  position: absolute;
  bottom: 2vh;
  left: -53px;
  transform: rotate(175deg);
}

.preloader__tentacle5 {
  position: absolute;
  top: 0px;
  left: 60vw;
  transform: rotate(309deg);
}

.preloader__tentacle6 {
  position: absolute;
  bottom: -80px;
  left: 20vw;
  transform: rotate(261deg);
}

@keyframes sailing {
  0% {
    top: 20vh;
    left: -7vw;
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(0deg);
  }
  15% {
    top: 20vh;
    left: 17vw;
    transform: rotate(40deg);
  }
  30% {
    top: 50vh;
    left: 27vw;
    transform: rotate(9deg);
  }
  50% {
    top: 70vh;
    left: 47vw;
    transform: rotate(-5deg);
  }
  60% {
    top: 70vh;
    left: 57vw;
    transform: rotate(-30deg);
  }
  80% {
    top: 40vh;
    left: 67vw;
    transform: rotate(8deg);
  }
  90% {
    top: 50vh;
    left: 87vw;
    transform: rotate(-2deg);
  }
  100% {
    top: 50vh;
    left: 107vw;
    transform: rotate(2deg);
  }
}

.preloader__ship {
  position: absolute;
  top: 50vh;
  left: 107vw;
  transform: rotate(2deg);

  width: 90px;

  animation: sailing 4s linear;
}
