.map-players-wrapper {
  position: absolute;
  top: 0px;
  left: 20px;
  right: 20px;
  bottom: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.map-players {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 9;
}

.map-players__item {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 42px;
  height: 42px;

  margin: -10px -10px;

  text-transform: uppercase;
  color: white;
  background: #39ade3;
  border-radius: 21px;
  box-shadow: -2px 2px 4px rgb(0 0 0 / 25%);
}

/* .map-players__item:nth-child(1) {
  left: 22px;
}
.map-players__item:nth-child(2) {
  left: 7px;
}
.map-players__item:nth-child(3) {
  right: 7px;
}
.map-players__item:nth-child(4) {
  right: 22px;
} */
