.button {
  position: relative;

  width: 366px;
  height: 66px;

  /* margin-top: 16px; */
  margin-top: 0;
  padding: 0;

  background: linear-gradient(#f1ad30 50%, #9E6F12 100%);
  border-radius: 50px;
  border: none;

  font-family: "Onest-VariableFont_wght";;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  /* or 127% */

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;

  cursor: pointer;
}

.button:hover {
  opacity: 0.8;
}

.button:active {
  opacity: 0.8;
}

.button:disabled,
.button[disabled] {
  background: #c1c1c1;
  color: #ffffff;
  cursor: default;
}

.button:disabled:hover,
.button[disabled]:hover {
  opacity: 1;
}

.button_gradient-red {
  background: linear-gradient(175.27deg, #cc2600 49.98%, #921e04 96.18%),
    linear-gradient(184.73deg, #921e04 3.82%, #d22700 50.02%);
}

.button_red-small {
  /* margin-top: 38px; */

  width: 207px;
  height: 50px;
  background: linear-gradient(175.27deg, #cc2600 49.98%, #921e04 96.18%),
    linear-gradient(184.73deg, #921e04 3.82%, #d22700 50.02%) !important;
}

.button_red-small::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  width: 40px;
  height: 50px;

  background-image: url("../../assets/images/svg/monogram.svg");
}

.button_red-small::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;

  width: 40px;
  height: 50px;

  background-image: url("../../assets/images/svg/monogram.svg");

  transform: rotate(180deg);
}
