.statistics {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: 63vh;
  max-height: 600px; */
}

.statistics-cards {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
}

.statistics-cards__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 17.5%;
  /*height: 170px;*/
  padding: 28px 34px;
  background-color: rgba(255, 255, 255, 0.57);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.statistics-cards__count {
  margin: 0;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 60px;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.statistics-cards__text {
  max-width: 160px;
  margin: 0;
  margin-top: 6px;
  text-align: center;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.statistics-title {
  margin: 0;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.statistics-steps {
  width: 100%;
  /* height: 240px; */
  margin-top: 20px;
}

.statistics-steps::-webkit-scrollbar {
  width: 7px;
}
.statistics-steps::-webkit-scrollbar-track {
  background-color: #fff7e9;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.statistics-steps::-webkit-scrollbar-thumb {
  background: linear-gradient(#e32a00 50%, #8C1313 100%);
  border-radius: 10px;
}

.statistics-steps__item {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 10px;
}

.statistics-steps__number {
  position: relative;
  width: 50px;
}

.statistics-steps__number-count {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.statistics-steps__image {
  width: 50px;
  height: 50px;
}

.statistics-steps__arrow {
  margin: 0 20px;
}

.statistics-steps__text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #a92406;
}
