.modal-wrap {
  position: fixed;
  /* display: block; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  margin: 0 auto;

  overflow-x: hidden;
  background-color: rgba(31, 32, 41, 0.75);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
  z-index: 1111111;
}

.visible {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.modal {
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  display: block;
  /*width: 60vw;*/
  width: 1080px;
  /*height: 65%;*/
  /* height: 700px; */
  height: 80vh;
  max-height: 700px;
  min-height: 500px;
  min-width: 400px;
  margin: 0 auto;
  padding: 45px 40px;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  border-radius: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  align-self: center;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  opacity: 0;

  box-sizing: border-box;

  transform: scale(0.6);
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
  transform: scale(0);
}

.visible .modal {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}

.modal-title {
  margin: 0;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  background: linear-gradient(#e32a00 50%, #8C1313 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  white-space: nowrap;
}

.select-modal__title {
  margin: 0;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  background: linear-gradient(#e32a00 50%, #8C1313 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  white-space: nowrap;
}

.select-modal__title_center {
  text-align: center;
}
