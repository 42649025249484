.login {
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 120px 0 0 100px;
}

.login__title {
  position: relative;

  margin: 0;

  font-family: "Pieces of Eight Cyrillic AA";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  /* identical to box height */

  text-transform: uppercase;

  color: #a92406;

  text-shadow: 0px 4px 2px rgba(255, 255, 255, 0.45);
}

.login__parchment {
  position: absolute;
  top: 0;
  left: 0;

  user-select: none;
}

.login__tentacle-left-top {
  position: absolute;
  top: -107px;
  left: -199px;

  user-select: none;
}

.login__tentacle-top-right {
  position: absolute;
  top: -187px;
  left: 273px;

  user-select: none;
}

.login__tentacle-top-middle {
  position: absolute;
  top: -152px;
  left: 132px;

  user-select: none;
}

.login__tentacle-middle-left {
  position: absolute;
  top: 308px;
  left: -61px;

  user-select: none;
}

.login__tentacle-bottom-left {
  position: absolute;
  bottom: -200px;
  left: -213px;

  user-select: none;
}

.login__tentacle-bottom-middle {
  position: absolute;
  bottom: -225px;
  left: 55px;

  user-select: none;
}

.auth {
  position: relative;
  display: flex;

  margin-top: 40px;
}

.auth__button {
  padding: 0;
  display: flex;
  align-items: center;

  border: none;
  background-color: transparent;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  color: #001439;
  cursor: pointer;
}

.auth__button:hover {
  opacity: 0.8;
}

.auth__button_red {
  color: #a92406;
}

.auth__button_opacity {
  opacity: 0.3;
}

.auth__button_ml {
  margin-left: 44px;
}

.auth__button-logo {
  padding-right: 4px;
}

.login__input {
  position: relative;

  margin-top: 15px;
  padding-left: 15px;

  width: 390px;
  height: 50px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: rgba(65, 16, 5, 0.7);

  background: #f3bb60;
  border: 2px solid #411005;
  outline: none;
  box-sizing: border-box;
  border-radius: 25px;
}

.login__forgot {
  display: block;
  position: relative;

  margin-top: 20px;

  border: none;
  background-color: transparent;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #a92406;

  cursor: pointer;
}

.login__forgot:hover {
  opacity: 0.8;
}

.login__input-error {
  height: 18px;

  margin: 0;
  margin-top: 18px;
  margin-bottom: 2px;

  color: red;
  font-size: 14px;
  line-height: 18px;
  font-family: "Mulish";
  font-weight: 400;
  z-index: 12312;
}

.login__input-success {
  height: 18px;

  margin: 0;
  /* margin-top: 18px; */
  margin-bottom: 2px;

  color: #000;
  font-size: 14px;
  line-height: 18px;
  font-family: "Mulish";
  font-weight: 400;
  z-index: 12312;
}

.login__forgot-container {
  display: flex;
  align-items: baseline;

  z-index: 12321;
}

.login__file {
  display: block;

  max-width: 130px;

  margin-left: auto;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;

  color: #a92406;

  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.login__file:hover {
  opacity: 0.8;
}

.login__file-input {
  visibility: hidden;
  width: 0;
  height: 0;
}
