.modal-card-content-title {
  margin: 0;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  background: linear-gradient(#e32a00 50%, #8C1313 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  white-space: nowrap;
}

.cards {
  /* display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap; */

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 20px;
  overflow-y: auto;

  width: 100%;
  height: 87%;

  margin-top: 20px;
  padding-right: 16px;

  /* overflow-y: auto; */
}

.cards::-webkit-scrollbar {
  width: 7px;
}
.cards::-webkit-scrollbar-track {
  background-color: #fff7e9;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.cards::-webkit-scrollbar-thumb {
  background: linear-gradient(270deg, #961e02 0%, #e32a00 100%);
  border-radius: 10px;
}

.card {
  /* width: 20%; */
  /* margin-bottom: 20px; */
  /*padding: 15px;*/
  background: rgba(255, 255, 255, 0.57);
  /*box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08);*/
  border-radius: 10px;
  text-decoration: none;
}

.card-image {
  width: 100%;
  /*height: 141px;*/
  border-radius: 10px;
}

.card-job {
  margin: 0;
  margin: 10px 0;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-name__block {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  min-height: 34px;
}

.card-name__icon {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  height: 30px;
  font-size: 16px;
  border-radius: 50%;
  background-color: #39ade3;
  color: #ffffff;
}

.card-name {
  width: 100px;
  margin: 0;
  margin-left: 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.card-name__open {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 104%;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.card-name__404 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 104%;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  opacity: 0.3;
}

.modal-card {
  padding-right: 16px;
}
