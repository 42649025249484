.bottom-menu {
  display: flex;
  align-items: center;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  height: 85px;

  background: radial-gradient(#884116, #481F07);
}

.bottom-menu__button_big {
  width: 200px;
  height: 50px;

  margin-top: 0;
  margin-left: 70px;
}

.bottom-menu__button_normal {
  width: 150px;
  height: 50px;

  margin-top: 0;
}

.bottom-menu__button_gradient-red {
  background: linear-gradient(175.27deg, #cc2600 49.98%, #921e04 96.18%),
    linear-gradient(184.73deg, #921e04 3.82%, #d22700 50.02%);
}

.bottom-menu__button_gradient-red {
  background-color: #c72500;
}

.bottom-menu__list {
  display: flex;

  margin: 0;
  padding: 0;
  margin-left: 60px;
}

.bottom-menu__item-team {
  position: relative;
  list-style-type: none;
  margin-right: 160px;
}

.bottom-menu__item {
  position: relative;

  margin-left: 42px;

  list-style-type: none;
}

.bottom-menu__exclamation {
  position: absolute;
  top: -19px;
  right: 4px;
}

.minimap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: none;

  top: -123px;

  width: 311px;
  height: 305px;

  margin-left: auto;
  margin-right: 50px;

  background-image: url("../../assets/images/miniMapForest.png");
  background-position: center center;
  padding-left: 11px;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 111111;
}

.minimap__row {
  /* width: 155px; */
  /* height: 17px; */

  pointer-events: none;
  /*transform: scale(2);*/
}

.minimap__row_odd {
  position: relative;
  left: -5px;

  margin-top: -8px;
  margin-bottom: -8px;
}

.minimap__row_land {
  /*background-image: url("../../assets/images/minimaoCity.png");*/
  left: -6px;
  margin-bottom: -7px;
  margin-top: -7px;
}

.minimap__image {
  width: 10px;
  height: 10px;
}

.minimap__image_land {
  width: 16px;
  height: 16px;
  margin-right: -3px;
}
