.stat-item {
  display: flex;
  flex: none;
  padding-right: 60px;
}

.stat-item:last-child {
  padding-right: 0;
}

.stat-item__icon {
  width: 20px;
  height: 20px;
}

.stat-item__button {
  margin-left: 10px;
  padding: 0;

  background: none;
  border: none;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  /* text-decoration: underline; */
  color: white;
  border-bottom: 1px solid white;

  cursor: pointer;
}

.stat-item__button:hover {
  opacity: 0.8;
}

.stat-container {
  display: flex;
  justify-content: space-between;

  margin-left: auto;
  padding-left: 80px;
  width: 100%;
}
