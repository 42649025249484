.modal-profile {
  height: 100%;

}

.modal-profile::-webkit-scrollbar {
  width: 7px;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  color: transparent;
}
.modal-profile::-webkit-scrollbar-track {
  background-color: #fff7e9;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.modal-profile::-webkit-scrollbar-thumb {
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  border-radius: 10px;
}

.modal-profile__top-block {
  display: flex;
}

.modal-profile__input-avatar {
  position: absolute;

  height: 70px;
  width: 70px;

  visibility: hidden;
}

.modal-profile__label {
  position: relative;
}

.modal-profile__avatar {
  width: 270px;
  height: 213px;

  border-radius: 10px;

  object-fit: cover;
  cursor: pointer;
}

.modal-profile__avatar_disabled {
  cursor: default;
}

.modal-profile__avatar:active {
  opacity: 0.8;
}

.modal-profile__avatar-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 270px;
  height: 304px;
}

.modal-profile__info {
  width: 100%;

  margin-left: 30px;
}

.modal-profile__team {
  display: flex;
}

.modal-profile__team-avatar {
  width: 90px;
  height: 90px;

  object-fit: cover;

  filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
  border-radius: 45px;
}

.modal-profile__team-info {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 20px;
}

.modal-profile__team-title {
  font-family: "Onest-VariableFont_wght";
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.modal-profile__team-name {
  margin-top: 2px;

  font-family: "Onest-VariableFont_wght";
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.modal-profile__user {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 17px 10px;
  /* height: 91px; */


}

.modal-profile__name {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 20px;
  line-height: 31px;
  text-transform: uppercase;
  color: #341A1A;
}

.modal-profile__role {
  font-family: "Mulish";
  font-weight: 500;
  font-size: 13px;
  line-height: 26px;
  text-transform: uppercase;
  color: #786A6A;
}

/* .modal-profile__user {
  margin-top: 20px;
  padding: 16px 20px;

  background: #ffeed1;
  border-radius: 5px;
}

.modal-profile__name {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;

  color: #a92406;
}

.modal-profile__job {
  margin-top: 8px;

  font-family: "Mulish";
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: #a92406;
} */

.modal-profile__stats {
  display: flex;
  justify-content: space-between;
  width: 100%;

  margin-top: 15px;
}

.modal-profile__stat {
  /* max-width: 140px; */
  width: 100%;

  padding: 25px 16px 16px 16px;
  margin-right: 20px;

  background: linear-gradient(#F7F7F7, #EAE5DD);
  border-radius: 5px;

  box-sizing: border-box;
}

.modal-profile__stat:last-child {
  margin-right: 0;
}

.modal-profile__stat-count {
  font-family: "Mulish";
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #a92406;
}

.modal-profile__stat-name {
  font-family: "Mulish";
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: #786A6A;
}

.modal-profile__achievements {
  display: flex;
  justify-content: space-between;

  margin-top: 20px;
  padding-bottom: 20px;
}

.modal-profile__achievement-container {
  display: flex;
  flex-wrap: nowrap;
}

.modal-profile__achievement {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 165px;
  height: 189px;

  padding: 23px 15px 10px;
  margin-right: 24px;

  border-radius: 5px;

  opacity: 0.2;

  box-sizing: border-box;
}

.modal-profile__achievement:last-child {
  margin-right: 0;
}

.modal-profile__achievement_active {
  opacity: 1;
}

.modal-profile__achievement-card-bottom-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.modal-profile__achievement-title {
  margin-top: 21px;

  font-family: "Mulish";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.modal-profile__achievement-description {
  margin-top: 9px;

  max-width: 150px;

  font-family: "Mulish";
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.modal-profile__motivation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 90px;
  width: calc(100% / 3 - 40px / 3);

  margin-left: auto;
  padding: 10px;

  box-sizing: border-box;
  background: linear-gradient(#F7F7F7, #EAE5DD);
  border-radius: 10px;
}

.modal-profile__motivation-title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: #786A6A;
}

.modal-profile__motivation-sum {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  text-transform: uppercase;
  background: linear-gradient(#e32a00 50%, #8C1313 100% );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  white-space: nowrap;
}

.modal-profile__motivation-date {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: #a92406;
}

.modal-profile__kpi-container {
  display: flex;
  justify-content: space-between;

  margin-top: 15px;
}

.modal-profile__kpi {
  width: calc(100% / 3 - 40px / 3);
  height: 92px;

  padding: 15px 10px;

  background: linear-gradient(#F7F7F7, #EAE5DD);
  border-radius: 5px;
  box-sizing: border-box;
}

.modal-profile__kpi-title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: #786A6A;
}

.modal-profile__kpi-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.modal-profile__kpi-num {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.modal-profile__kpi-num-subtitle {
  margin-top: 2px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  text-transform: uppercase;
  color: #786A6A;
}

.modal-profile__kpi-row-text {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  margin-bottom: 12px;
}

.modal-profile__kpi-column_first {
  width: 34px;
}

.modal-profile__kpi-num-subtitle-right {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 10px;
  text-align: right;
  text-transform: uppercase;
  color: #786A6A;
}

.modal-profile__kpi-num-right {
  width: 45px;
  margin-left: 8px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
