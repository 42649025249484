.dropdown-container {
  position: relative;

  width: 260px;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 48px;

  padding: 13px 20px;
  margin-bottom: 5px;

  border: 2px solid #D1D1D1;
  border-radius: 50px;
  background: transparent;

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #341A1A;

  cursor: pointer;
  box-sizing: border-box;
}

.dropdown-list__container {
  position: absolute;
  top: 13px;
  left: 0;
  z-index: -1;

  width: 260px;
}

.dropdown-list {
  padding: 50px 20px 20px 20px;
  margin: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: #341A1A;

  background-color: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  border-radius: 20px;

  box-sizing: border-box;
}

.dropdown-list__item {
  list-style: none;
  margin-bottom: 10px;
  opacity: 0.5;


  cursor: pointer;
}

.dropdown-list__item:hover {
  opacity: 1;
}

.dropdown-list__item:last-child {
  margin-bottom: 0;
}
