.map {
  position: relative;

  width: 100vw;
  height: 100vh;
  /* height: calc(100vh - 85px - 70px); */
  /* background-color: rgb(207, 93, 93); */

  overflow: hidden;
  /*background-image: url("../../assets/images/forestBackground.png");*/
  background-size: cover;
}

.cell {
  position: relative;
  width: 186px;
  height: 180px;
}

.cell_dropdown {
  z-index: 99;
}

.cell__title {
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  /* width: 50px; */

  margin-left: auto;
  margin-right: auto;

  font-family: "Mulish";
  text-align: center;
  text-transform: uppercase;
  color: white;
}

.cell__decoration {
  position: absolute;
  bottom: 27px;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;

  width: 100px;
  height: 100px;
  /* transform: rotate(-123.48deg); */

  object-fit: contain;
}

@keyframes toIsland {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  10% {
    transform: translate(0, 0) rotate(-9deg);
  }
  20% {
    transform: translate(0, 0) rotate(-9deg);
  }
  50% {
    transform: translate(20px, -40px) rotate(-9deg);
  }
  100% {
    transform: translate(40px, -80px) rotate(-9deg);
  }
}

.cell__decoration_ship {
  transform: rotate(0deg);
  transition: all 0.8s ease-in-out;
  z-index: 31231321;
}

.cell__decoration_speciality {
  transform: rotate(15deg);
}

.map-row {
  position: relative;
  display: flex;
  width: 100%;
  height: 180px;

  padding-left: 78px;

  pointer-events: none;
}

.map-row_odd {
  /* left: -78px; */

  margin-top: -44px;
  margin-bottom: -44px;

  padding-left: 0;
}

.ship-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 186px;
  height: 180px;
  z-index: 1238;
  pointer-events: none;
}

.ship-container_transition {
  transition: all 2s ease-in-out;
}

@keyframes swimming {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-3deg);
  }
  60% {
    transform: rotate(2deg);
  }
  80% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.ship {
  position: absolute;
  bottom: 27px;
  left: 8px;
  right: 0;

  margin-left: auto;
  margin-right: auto;

  width: 100px;
  height: 100px;

  object-fit: contain;
}

.ship_animate {
  animation: swimming 2s infinite;
}
