.card-item {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.card-item__content {
    width: 47%;
}

.card-item__block {
    margin-bottom: 40px;
}

.card-item__title {
    margin: 0;
    margin-bottom: 20px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    color: #A92406;
}

.card-item__descr {
    margin: 0;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    color: #A92406;
}

.card-item__list {
    padding-left: 30px;
}

.card-item__list-item {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 23px;
    color: #A92406;
}
