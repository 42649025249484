.admin-map-menu {
  position: fixed;
  top: 80px;
  left: 0;
  bottom: 0;

  height: calc(100vh - 80px);
  width: 20vw;
  min-width: 300px;
  max-width: 400px;

  background-color: cadetblue;
  z-index: 9999;
  overflow-y: auto;
}

.admin-map-menu__loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #12312330;
}

.admin-map-menu__button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: calc(100% - 20px);
  height: 50px;

  margin: 10px;

  border: none;
  border-radius: 5px;
  background-color: #ffe4b8;
  cursor: pointer;
}

.admin-map-menu__button:hover {
  opacity: 0.7;
}

.admin-map-menu__button_active {
  background-color: #bab2a6;
}

.admin-map-menu__item {
  margin: 10px;
  padding-bottom: 15px;

  border: none;
  border-radius: 5px;

  background-color: #ffe4b8;
  cursor: pointer;
}

.admin-map-menu__item:hover {
  opacity: 0.7;
}

.admin-map-menu__item_4 {
  background-color: #eaf0ff;
}
