.interview {
  display: flex;
  /*justify-content: space-between;*/
  margin-top: 20px;
  height: calc(100% - 60px);
  overflow-y: auto;
}

.interview::-webkit-scrollbar {
  width: 7px;
}
.interview::-webkit-scrollbar-track {
  background-color: #fff7e9;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.interview::-webkit-scrollbar-thumb {
  background: linear-gradient(270deg, #961e02 0%, #e32a00 100%);
  border-radius: 10px;
}

.interview-content {
  margin-left: 40px;
}

.interview-title {
  margin: 0;
  margin-top: 10px;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.interview-text {
  margin: 0;
  margin-bottom: 20px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 120%;
  color: #786A6A;
}

.interview-list {
  padding-left: 0;
  margin: 0;
  margin-top: 20px;
}

.interview-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin-bottom: 17px;
}

.interview-item:last-child {
  margin-bottom: 0;
}

.interview-item__position,
.interview-item__count {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.interview-map {
  position: relative;
  flex: none;

  width: 525px;
  height: 525px;

  overflow: hidden;
}

.interview-error {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;

  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
  background-color: #ffe4b8;
}
