.select-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
}

.select-content_text {
  margin: 0;
  padding: 20px 0;

  font-family: "Mulish";
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
  text-align: center;

  color: #000000;
}

.select-content__buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  row-gap: 10px;
}

.select-content__button {
  width: 133px;
  height: 50px;

  margin: 0 5px;
}
