.map-event {
  position: absolute;
  top: 54%;
  right: 50%;

  width: 450px;

  padding: 30px;

  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background: #fbf0dc;

  transform: translate(50%, -50%) scale(0.19) rotate(15deg);

  box-sizing: border-box;
  z-index: 1;
}

.map-event_4 {
  background: #eaf0ff;
}

.map-event_5 {
  background: #fbf0dc;
}

.map-event_6 {
  width: 230px;
  height: 260px;

  padding: 15px;

  background: #fbf0dc;

  transform: translate(50%, -50%) scale(0.35) rotate(15deg);
}

.map-event__image {
  width: 100%;
  max-height: 276px;

  object-fit: cover;
  border-radius: 10px;
}

.map-event__image_7 {
  max-height: 450px;
  object-fit: contain;
}

.map-event__title {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 154px;

  margin: 0;
  margin-top: 20px;

  font-family: "Onest-VariableFont_wght";
  font-weight: 700;
  font-size: 36px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.map-event__title_4 {
  color: #2858b7;
}

.map-event__title_5 {
  color: #a92406;
}

.map-event__title_6 {
  height: 74px;

  margin-top: 10px;

  font-style: normal;
  font-size: 20px;
  line-height: 25px;
  color: #a92406;
}

.map-event__title_7 {
  display: none;
}
