.chat-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  top: 90px;
  right: 70px;

  width: 90px;
  height: 90px;

  background: linear-gradient(#ED483D 40%, #9E1212 100%);
  border-radius: 45px;

  cursor: pointer;
}

.chat-button:hover {
  background: linear-gradient(147.93deg, #ff720f 13.31%, #d15903 84.65%);
}

.chat-button:active {
  opacity: 0.9;
}

.chat-button__icon {
}

.chat-button__title {
  margin: 0;
  margin-top: 7px;

  font-family: "gothampro_bold";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.chat-button__exclamation {
  position: absolute;
  right: -10px;
  top: 10px;
}
