.admin-games-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-games-title {
  margin: 0;
  padding-top: 100px;
  padding-bottom: 50px;
  max-width: 1100px;
  margin: 0 auto;
}

.admin-games-container {
  max-width: 1100px;

  margin: 0 auto;
  margin-bottom: 50px;
  padding-top: 100px;

  border-collapse: collapse;
}

.admin-game-head {
  background-color: rgba(0, 0, 0, 0.25);
}

.admin-game-head__tr {
  position: relative;
}

.admin-game-head__th {
  padding: 5px;

  border: 1px solid black;
}

.admin-game {
  background-color: rgba(0, 0, 0, 0.05);
}

.admin-game__td {
  padding: 5px;
  border: 1px solid #dee2e6;
}

.admin-game__link {
}

.admin-game__image {
  max-width: 100px;
}

.admin-game__button {
  display: block;

  margin-top: 20px;
  padding: 10px;

  text-decoration: none;
  color: #fff;

  outline: none;
  border: none;
  background: #921e04;
  border-radius: 5px;
  cursor: pointer;
}

.admin-game__button:first-child {
  margin-top: 0;
}

.admin-game__button:hover {
  opacity: 0.8;
}
