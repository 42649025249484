.progress-line {
    display: flex;
    justify-self: flex-start;
    width: 100%;
}

.progress-bar {
    height: 32px;
    margin-left: 15px;
    border-radius: 50px;
    background: linear-gradient(#FFE177 50%, #FE9800 100%);
}
