.container-start {
  min-height: 100vh;
  min-width: 1440px;

  background-color: #0e0e0e;
  background-image: url("./../../assets/images/startScreenBackground.jpg");
  background-size: cover;

}

.wrapper-start {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.start {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* max-width: 1440px;
  width: 100%; */
  width: 1440px;

  padding: 175px 90px 0 106px;
  margin: 0 auto;
  box-sizing: border-box;
}

.start-menu {
  /*margin-top: 13px;*/
}

/*.start-menu__header {
  margin: 0;

  font-family: "Pieces of Eight Cyrillic AA";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 81%;
  !* or 76px *!

  text-transform: uppercase;

!*  background: linear-gradient(
      180deg,
      rgba(255, 184, 0, 0) 36.75%,
      rgba(121, 36, 0, 0.4) 78.31%
    ),
    linear-gradient(104.97deg, #ffcf55 1.66%, #ff7a00 88.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;*!
}*/

.start-menu__list-container {
  margin: 0;
  margin-top: 36px;
  padding: 0;
}

.start-menu__list {
  margin-top: 15px;
  list-style-type: none;
}

.button_red-medium {
  background:
    linear-gradient(#ED483D 50%, #9E1212 100%);
}

.game-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* margin-top: 70px; */
  margin-right: 30px;
}

.game-info__container {
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #FDFFF2;
  border-radius: 30px;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.3);


  width: 640px;
  height: 718px;

  /* background-image: url("../../assets/images/svg/gameFrame.svg"); */
}

.game-info__container:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;

  width: 640px;
  height: 718px;

}

.game-info__img {
  display: block;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  width: 640px;
  height: 420px;

  background-color: black;
  filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.3));
}

.game-info__steps {
  position: absolute;
  top: -20px;
  left: -73px;

  width: 176px;
  height: 176px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-image: url("../../assets/images/svg/coin.svg");
  z-index: 1;
}

.game-info__steps-title {
  margin: 0;
  margin-bottom: 13px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
  line-height: 33px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.game-info__steps-text {
  margin: 0;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 110.9%;
  /* or 16px */
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.game-info__title {
  margin-top: 70px;
  margin-bottom: 0;

  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 58.65px;
  text-align: center;

  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.game-info__locations {
  margin-top: 20px;
  margin-bottom: 0;

  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25.5px;
  text-align: center;

  color: #000000;
}

.game-info__locations_opened {
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.game-info__button_container {
  display: flex;
  justify-content: center;
}

.game-info__team {
  margin-top: 23px;
  padding: 0 0 6px;

  border: none;
  font-family: "Mulish", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  background: linear-gradient(#ED483D 30%, #9E1212 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  z-index: 1000;

  border-bottom: 1px solid #FF9D23;

  cursor: pointer;
}

.game-info__team:hover {
  opacity: 0.8;
}

.footer {
  margin: 0 auto;
  max-width: 1228px;
  padding-bottom: 50px;
}

.footer__logo {
  /* padding: 5px 0 96px 106px; */
}
