.user-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-item.pointer {
  cursor: pointer;
}

.user-item.pointer:hover {
  opacity: 0.85;
}

.user-item:last-child {
  margin-bottom: 0;
}

.user-item__block {
  display: flex;
  align-items: center;
  width: 330px;
}

.user-item__name-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  background-color: #2cf0aa;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
}

.user-item__crew-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #a92406;
}

.user-item:first-child .user-item__block .user-item__crew-icon {
  background: linear-gradient(#ED8872 10%, #A92406 100%);
  color: #ffffff;
}

.user-item:nth-child(2) .user-item__block .user-item__crew-icon {
  background: linear-gradient(#FFA18C 10%, #F04F2C 100%);
  color: #ffffff;
}

.user-item:nth-child(3) .user-item__block .user-item__crew-icon {
  background: linear-gradient(#FFC7A8 10%, #FF8541 100%);
  color: #ffffff;
}

.user-item__name {
  width: 140px;
  margin: 0;
  margin-bottom: 5px;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  background: linear-gradient(#e32a00 50%, #8C1313 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.user-item__job {
  margin: 0;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: #786A6A;
}

.user-item__job_small {
  font-size: 9px;
  line-height: 11px;
}

.user-item__job-position {
  font-family: "Mulish";
  font-weight: 600;
  font-size: 9px;
  line-height: 11px;
  text-transform: uppercase;
  color: #786A6A;
}

.user-item__crew {
  margin: 0;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
}

.user-item__param {
  width: 130px;
}

.user-item__param_small {
  width: 70px;
  margin-right: 34px;

  text-align: center;
}

.user-item__param-crew {
  width: 120px;
  margin-right: 80px;
}

.user-item__param-count {
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #000000;
}

.user-item__param-count_small {
  font-size: 14px;
  line-height: 18px;
}

.user-item__param-count_bold {
  font-weight: 900;
}

.user-item__param-title {
  margin: 0;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: #786A6A;
}

.user-item__param-title_small {
  font-size: 11px;
  line-height: 14px;
}

.user-item__param-title_bold {
  font-weight: 900;
}

.user-item__menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 20px;
  background: linear-gradient(#f1ad30 50%, #9E6F12 100%);
  cursor: pointer;
}

.user-item__menu-dot {
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: 0 1.5px;
  background-color: #ffffff;
}

.user-item__menu-list {
  /* display: none; */
  position: absolute;

  bottom: 82%;
  width: 152px;

  padding-left: 0;
  padding: 18px 19px;
  margin: 0;
  margin-bottom: 15px;

  border-radius: 10px;
  background-color: #FFFFFF;
  box-sizing: border-box;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.user-item__menu-list_bottom {
  bottom: -448%;
}

.user-item__menu-list:after {
  content: "▼";
  color: #ffeed1;
  position: absolute;
  bottom: -13px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  text-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
}

.user-item__menu-list_bottom::after {
  bottom: auto;
  top: -10px;
  transform: translateX(-50%) rotate(180deg);
}

.user-item__menu-list-item {
  display: block;

  margin-bottom: 15px;

  list-style-type: none;
}

.user-item__menu-list-item:last-child {
  margin-bottom: 0;
}

.user-item__menu-list-button {
  width: 100%;
  padding: 0;
  border: none;
  font-family: "Onest-VariableFont_wght";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  background-color: transparent;
  color: #341A1A;
  cursor: pointer;
  text-align: left;
}

.user-item__menu-list-button:hover {
  background: linear-gradient(104.97deg, #d23329 1.66%, #9e1212 88.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.flag-image {
  position: absolute;
  left: 0;
}

.user-profile__kpi-container {
  display: flex;
  justify-content: space-between;

  /* margin-top: 15px; */
}

.user-profile__kpi {
  margin-right: 34px;
}

.user-profile__kpi-title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
}

.user-profile__kpi-row {
  display: flex;

  /* margin-top: 10px; */
}

.user-profile__kpi-num {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.user-profile__kpi-num-subtitle {
  margin-top: 4px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #786A6A;
}

.user-profile__kpi-row-text {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  margin-top: 4px;
}

.user-profile__kpi-column_first {
  width: 34px;
}

.user-profile__kpi-column_second {
  margin-left: 30px;
  padding-right: 16px;

  border-right: 1px solid #a9240640;
}

.user-profile__kpi-num-subtitle-right {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 9px;
  text-align: right;
  text-transform: uppercase;
  color: #786A6A;
}

.user-profile__kpi-num-right {
  width: 45px;
  margin-left: 8px;

  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #000000;
}
